.topBar {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    padding: 0 1em;
    background-color: white;
    font-size: 1.1em;
    font-weight: 500;
    line-height: 2.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}

.logo {
    display: flex;
    flex-direction: column;
    row-gap: 0.2em;
    margin: 0.25em 0 0 0;
}

.logo div {
    align-self: flex-end;
    display: flex;
    font-size: 0.8rem;
    line-height: 1.1rem;
    font-style: italic;
    text-align: right;
    margin-right: 0.9em;
    font-weight: 500;
    color: #777;
}

.logo img {
    align-self: flex-end;
    display: block;
    width: 120px;
    height: 38px;
}

.logo a {
    border-bottom: none;
}
