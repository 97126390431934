.nav {
    display: flex;
    column-gap: 1.5em;
}

.nav a {
    color: #444;
    text-decoration: none;
    border-bottom: none;
}

.nav a:hover {
    color: #004a9a;
}

.nav .active {
    color: #004a9a;
}

.pulldown {
    position: relative;
}

.pulldown > ul {
    display: none;
}

.pulldown > span::after {
    content: " ⏷";
}

.pulldown:hover > span {
    color: #004a9a;
}

.pulldown:hover > ul {
    position: absolute;
    left: -0.5em;
    top: 100%;
    display: block;
    background-color: white;
    list-style: none;
    white-space: nowrap;
    box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.5);
    line-height: 2em;
    border: 1px solid #ccc;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.pulldown li {
    padding: 0 0.5em;
}

.pulldown li:hover {
    background-color: #ccc;
}
