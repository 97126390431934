*,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    padding: 1em;
    background-color: #666;
    line-height: 1.4em;
}

body.admin {
    padding-top: 80px;
    background-color: #888;
}

#root {
    padding-bottom: 1em;
}

a {
    color: #444;
    border-bottom: 1px solid #004a9a;
    text-decoration: none;
}

/* a::after {
	content: " ➜";
} */

a:hover {
    color: #004a9a;
}

ul,
ol {
    margin-bottom: 1em;
}

ul.list {
    margin-left: 1em;
}

strong {
    font-weight: 500;
}

h2 {
    font-weight: 500;
    font-size: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.4em;
}

h3 {
    font-weight: 500;
    font-size: 1em;
    line-height: 2em;
    padding: 0 0.75em;
    background-color: #e0e0e0;
    border-radius: 5px 5px 0 0;
}

.required > span::before,
.required > h3::before {
    content: "✘ ";
    color: #090;
    font-size: 1.2em;
}

p {
    margin-bottom: 1em;
}

label.checkbox {
    display: inline-flex;
    align-items: center;
    column-gap: 0.25em;
    cursor: pointer;
    line-height: 1.3em;
    margin-right: 1em;
    margin-bottom: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    border-radius: 5px;
    padding: 2px 0.5em 2px 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

label.checkbox.multiLine {
    white-space: normal;
}

label.checkbox:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

label.checkbox input {
    flex: 0 1 auto;
}

textarea,
input[type="text"],
input[type="number"],
input[type="password"] {
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 1em;
    color: #333;
    padding: 0.75em 0.5em;
    line-height: 1.3em;
    vertical-align: middle;
    border: 1px solid #ddd;
    border-radius: 5px;
}

textarea {
    resize: none;
}

select {
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 1em;
    color: #333;
    padding: 0.25em 0.5em;
    line-height: 1.3em;
    vertical-align: middle;
    border: 1px solid #999;
    border-radius: 5px;
}

button {
    font-size: 1em;
    font-weight: 500;
    background-color: #004a9a;
    border: none;
    color: white;
    padding: 0 12px;
    line-height: 28px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 5px;
    outline: none;
}

button:active {
    background-color: #336eae;
}

button:disabled {
    opacity: 0.5;
}

div.signature canvas {
    width: 100%;
    height: 200px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

div.buttonRow {
    margin-top: 1em;
    display: flex;
    justify-content: flex-end;
    column-gap: 1em;
}

div.inputArea {
    padding-bottom: 1em;
}

div.inputArea.inPanel {
    padding: 0.5em 0.5em 0 0.5em;
    border-left: 2px solid #e0e0e0;
    border-right: 2px solid #e0e0e0;
}

div.startPanel {
    background-color: #e0e0e0;
    font-weight: 500;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 0 0.75em;
    line-height: 2em;
}

div.endPanel {
    height: 0.5em;
    border-left: 2px solid #e0e0e0;
    border-right: 2px solid #e0e0e0;
    border-bottom: 2px solid #e0e0e0;
    margin-bottom: 1em;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

div.inputArea > div {
    background-color: white;
    padding: 0.5em 0.75em;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

div.inputArea > div::after {
    clear: right;
    display: block;
    content: "";
}

div.inputArea button {
    float: right;
}

div.description {
    margin-bottom: 0.5em;
}

div.note {
    margin-bottom: 0.5em;
}

div.startPanel > div.note {
    margin-bottom: 0;
}

div.note > span {
    font-weight: 500;
    font-size: 0.85em;
    background-color: yellow;
    padding: 4px 8px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

div.multiCalendar {
    border-top: 1px solid #666;
    border-right: 1px solid #666;
    max-width: 800px;
    user-select: none;
    margin-bottom: 0.5em;
}

div.multiCalendar div.monthSelector {
    border-left: 1px solid #666;
    border-bottom: 1px solid #666;
    line-height: 2em;
    text-align: center;
    font-weight: 500;
}

div.multiCalendar div.monthSelector::after {
    clear: left;
    display: block;
    content: "";
}

div.multiCalendar div.monthSelector > div {
    float: left;
    width: 70%;
}

div.multiCalendar div.monthSelector > div:last-child,
div.multiCalendar div.monthSelector > div:first-child {
    width: 15%;
}

div.multiCalendar div.days > div.head {
    font-weight: 500;
    color: #444;
}

div.multiCalendar div.days > div {
    float: left;
    width: 14.28%;
    padding: 2px;
    border-left: 1px solid #666;
    border-bottom: 1px solid #666;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

div.multiCalendar div.days > div:nth-child(7n),
div.multiCalendar div.days > div:nth-child(7n-1) {
    background-color: #efefef;
}

div.multiCalendar div.days > div.notInMonth {
    color: #999;
}

div.multiCalendar div.days > div.click {
    cursor: pointer;
    font-weight: 500;
}

div.multiCalendar div.days > div.selected {
    background-color: #004a9a;
    color: white;
}

div.multiCalendar div.days > div.click:not(.selected):hover {
    background-color: #ffec0c;
}

div.multiCalendar div.days > div.selected:hover {
    opacity: 0.7;
}

div.multiCalendar div.days::after {
    clear: left;
    display: block;
    content: "";
}

div.simpleDateChooser {
    font-size: 1.2em;
    user-select: none;
    display: inline-block;
    margin-right: 1em;
}

div.multiCalendar svg {
    margin-top: 7px;
}

div.multiCalendar svg,
div.simpleDateChooser svg {
    width: 1em;
    height: 1em;
    fill: #004a9a;
    cursor: pointer;
    vertical-align: top;
    margin-right: 2px;
}

div.multiCalendar svg.right,
div.simpleDateChooser svg.right {
    transform: scaleX(-1);
    margin-left: 2px;
    margin-right: 0;
}

div.userImage {
    position: relative;
    display: inline-block;
    width: 24%;
    margin-right: 1%;
}

div.userImage img {
    width: 100%;
}

div.userImage div {
    position: absolute;
    left: 4px;
    top: 4px;
    color: #004a9a;
    font-weight: 500;
    font-size: 2em;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    padding: 8px;
}

div.userImage div:hover {
    background-color: rgba(255, 255, 255, 0.9);
}

div.error {
    background-color: #c00;
    color: white;
    font-weight: 500;
    padding: 6px 1em;
    margin-bottom: 1px;
}

#form {
    padding-top: 4em;
    max-width: 1024px;
    margin: 0 auto;
}

@media only screen and (max-width: 799px) {
    body {
        padding: 0.75em;
    }

    #form {
        font-size: 14px;
    }
}

#form div.error {
    margin-bottom: 1em;
    padding: 0.5em 1em;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
}

#form > div.formInfo {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    background-color: white;
    padding: 0.5em 1em;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    opacity: 0.9;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

h1 {
    font-weight: 500;
    font-size: 2em;
    line-height: 1em;
    color: #004a9a;
}

#form > div.formInfo > div {
    width: 120px;
}

#form > div.formInfo img {
    display: block;
    width: 100%;
}

#form > div.required {
    display: inline-block;
    background-color: #e0e0e0;
    border-radius: 5px;
    padding: 0 5px;
    font-weight: 500;
    margin-bottom: 1em;
}

div.success {
    background-color: #090;
    color: white;
    font-weight: 500;
    padding: 6px 1em;
    margin-bottom: 1px;
}

/* .required {
	background-color: greenyellow;
	padding: 2px 6px;
	border-radius: 5px;
} */

form {
    padding-bottom: 2em;
}

#tempSuccess {
    position: fixed;
    /* top: 0; */
    bottom: 100%;
    z-index: 2;
    width: 400px;
    left: 50%;
    margin-left: -200px;
    border-radius: 0 0 1em 1em;
    transition: 0.3s ease-out;
}

#tempSuccess.show {
    transform: translateY(100%);
}

div.spinner {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 2em;
    background-color: rgba(0, 0, 0, 0.6);
}

.sk-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
}
.sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.sk-circle .sk-child::before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: white;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
}
.sk-circle .sk-circle2::before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}
.sk-circle .sk-circle3::before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
.sk-circle .sk-circle4::before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}
.sk-circle .sk-circle5::before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}
.sk-circle .sk-circle6::before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}
.sk-circle .sk-circle7::before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}
.sk-circle .sk-circle8::before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}
.sk-circle .sk-circle9::before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}
.sk-circle .sk-circle10::before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}
.sk-circle .sk-circle11::before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}
.sk-circle .sk-circle12::before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.ldio-dyw8t9yacb div {
    position: absolute;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    top: 28px;
    left: 6px;
}
.ldio-dyw8t9yacb div:nth-child(1) {
    background: #d8011e;
    animation: ldio-dyw8t9yacb 7.142857142857142s linear infinite;
    animation-delay: -3.571428571428571s;
}
.ldio-dyw8t9yacb div:nth-child(2) {
    background: #01e53e;
    animation: ldio-dyw8t9yacb 7.142857142857142s linear infinite;
    animation-delay: 0s;
}
.ldio-dyw8t9yacb div:nth-child(3) {
    background: #d8011e;
    animation: ldio-dyw8t9yacb-o 7.142857142857142s linear infinite;
    animation-delay: -3.571428571428571s;
}
.loadingio-spinner-dual-ball-o20bp2k35w {
    width: 32px;
    height: 32px;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
}
.ldio-dyw8t9yacb {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.32);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-dyw8t9yacb div {
    box-sizing: content-box;
}

div.pikett label {
    display: block;
    margin-top: 1em;
}

div.pikett input[type="text"] {
    width: 5em;
}

div.pikett div.buttons {
    text-align: right;
}

button.spaceLeft {
    margin-left: 1em;
}

span.click {
    cursor: pointer;
}

span.click:hover {
    color: #004a9a;
}

div.panel {
    margin: 0 auto;
    max-width: 1240px;
    border-radius: 6px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}

div.panel.small {
    max-width: 640px;
}

div.panel > div.header {
    border-radius: 5px 5px 0 0;
    background-color: #004a9a;
    color: white;
    font-weight: 500;
    padding: 0 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div.panel > div.header > span {
    font-size: 1.3em;
    line-height: 2em;
}

div.panel form {
    padding: 1em;
}

div.panel.login {
    margin-top: 2em;
    max-width: 480px;
    padding-bottom: 0;
}

div.panel.login label {
    display: block;
    margin-bottom: 1em;
}

div.panel.login label span {
    font-size: 0.9em;
    font-weight: 500;
}

div.panel.login input {
    line-height: 1em;
    border: 1px solid #999;
}

div.panel.login button {
    float: right;
}

div.panel.login form {
    background-color: white;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
}

div.panel.login form::after {
    clear: right;
    display: block;
    content: "";
}

.spaceAfter {
    margin-bottom: 1em;
}

input.error {
    background-color: #f99;
}

ul.groups {
    list-style: none;
    border-top: 1px solid #999;
    margin-bottom: 0;
}

ul.groups > li {
    background-color: #fff;
    line-height: 2em;
    border-bottom: 1px solid #999;
    border-left: 1px solid #999;
    border-right: 1px solid #999;
}

ul.groups > li > div.head {
    position: relative;
    cursor: pointer;
    padding: 0 1em;
    line-height: 2.5em;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

ul.groups > li > div.head svg {
    width: 12px;
    margin-right: 6px;
    line-height: 2.5em;
    fill: #333;
}

ul.groups > li.selected div.head svg {
    width: 14px;
    margin-right: 4px;
}

ul.groups > li > div.head > span {
    position: absolute;
    top: 0;
    right: 1em;
    color: #c00;
    font-size: 1.5em;
}

ul.groups > li:hover div.head {
    background-color: #99b7d7;
}

ul.groups > li.selected div.head {
    background-color: #99b7d7;
}

ul.groups > li.selected > div.content {
    font-weight: 300;
    padding: 0.5em 1em;
}

ul.groups > li.selected > div.content div.buttons {
    display: flex;
    justify-content: flex-end;
}

p.description {
    background-color: white;
    padding: 1em;
    border-radius: 5px;
}

.panelBottom {
    background-color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

div.formData {
    display: flex;
}

ul span.dateTime {
    display: block;
    font-size: 0.8em;
    line-height: 1.4em;
}

div.formRow {
    padding-top: 0.5em;
}

div.formRow > label {
    display: inline-block;
    font-weight: 500;
    font-size: 0.8em;
    line-height: 1.3em;
    background-color: #e9e9e9;
    padding: 0 4px;
    border-radius: 4px;
    margin-bottom: 4px;
}

div.formRow img {
    display: block;
    max-width: 100%;
    margin-bottom: 0.5em;
}

div.formRow img:last-child {
    margin-bottom: 0;
}

div.userList {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1em 1em;
    padding: 1em;
}

div.userList > div {
    position: relative;
    flex-basis: calc((100% / 4) - 0.75em);
    cursor: pointer;
    line-height: 1.5em;
    background-color: #e6e6e6;
    padding: 0.5em 0.5em 0.5em 72px;
    border-radius: 32px 0.5em 0.5em 32px;
    min-height: 64px;
    overflow: hidden;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}

div.userList h4 {
    font-size: 1em;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

div.userList img {
    position: absolute;
    left: 0;
    top: 0;
    width: 64px;
    height: 64px;
    border-radius: 50%;
}

div.userList > div > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

div.userList > div:hover {
    background-color: #99b7d7;
}

div.userList > div.inactive {
    color: #666;
    text-decoration: line-through;
}

div.searchFilter {
    position: relative;
}

div.searchFilter input {
    border-radius: 1.5em;
    padding: 0.25em 0.75em;
    outline: none;
    margin: 0;
}

div.searchFilter svg {
    position: absolute;
    right: 4px;
    top: 3px;
    height: calc(100% - 7px);
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.1s ease-in-out;
}

div.searchFilter svg:hover {
    opacity: 1;
}

div.searchFilter svg:hover ~ input {
    text-decoration: line-through;
    color: #999;
}

#ask {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

#ask > div {
    width: 640px;
    position: relative;
    margin: 2em auto;
    border-radius: 1em;
    background-color: white;
}

#ask div.content {
    padding: 1em 1em 0 1em;
}

#ask div.buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: 1em;
}

div.editFormData {
    background-color: #ffec0c;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 0.5em;
    margin-bottom: 1em;
    overflow: hidden;
}

div.editFormData div.content {
    padding: 0.5em;
}

h4 {
    font-size: 1em;
    font-weight: 500;
}

div.editFormData h4:not(:first-child) {
    margin-top: 1em;
}

div.editFormAdditions {
    margin-bottom: 1em;
}

label.fieldLabel {
    display: block;
}

label.fieldLabel span {
    font-weight: 500;
    font-size: 0.8em;
    line-height: 1.3em;
}

div.info {
    margin-top: 1em;
    font-size: 0.8em;
    line-height: 1.3em;
    font-weight: 500;
    display: flex;
    align-items: center;
    column-gap: 4px;
}

ul.groupUserList {
    list-style: none;
}

ul.clean {
    list-style: none;
    margin-bottom: 0;
}

ul.clean input {
    margin-right: 4px;
}
